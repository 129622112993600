<template>
  <div data-app>
    <div class="row" v-if="showBulkMsgForm">
      <div class="col col-md-12" style="display: flex">
        <v-card>
          <v-card-title>
            <span class="headline">Send direct message</span>
          </v-card-title>
          <div class="row" style="padding-left: 6%; padding-right: 6%">
            <div class="col-md-12">
              <v-text-field
                v-model="message_title"
                label="Subject"
              ></v-text-field>

              <v-file-input
                style="font-size: 8px"
                label="file"
                @change="Preview_image"
                v-model="file"
              >
              </v-file-input>
            </div>

            <label>Message</label>
            <vue-editor
              style="width: 100%; height: 80%"
              v-model="message"
            ></vue-editor>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="custom-submit-button"
              text
              @click="showBulkMsgForm = false"
              >Cancel</v-btn
            >
            <v-btn class="custom-cancel-button" text @click="bulkActionHandler"
              ><b-spinner small type="grow" v-if="deleting"></b-spinner
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <v-card class="row m-0 p-0 d-flex">
          <div class="col-6 p-0 mb-4">
            <div class="py-4 px-4 border_b_silver">
              <span class="card-label font-weight-bolder Main_Blue"
                >Shortlisted candidates
              </span>
            </div>
          </div>
          <div class="col-6 p-0">
            <div
              class="py-3 px-4 border_b_silver text-right"
              style="margin-top: 1px"
            >
              <b-form-select
                :v-model="job_id"
                :options="jobs"
                size="sm"
                class="rounded-0"
                style="width: 40%"
                @change="
                  (e) => {
                    myFunc(e);
                  }
                "
              ></b-form-select>
            </div>
          </div>
          <div>
            <v-btn
              class="
                btn btn-sm btn-info
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              small
              v-if="bulkActionArr.length > 0"
              @click="showBulkMsgForm = true"
            >
              Bulk Message
            </v-btn>
          </div>

          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                headers_job_function_ch
              "
            >
              <thead>
                <tr class="text-left">
                  <th style="min-width: 250px" class="pl-7">
                    <span class="text-dark-75">Details</span>
                  </th>
                  <th style="min-width: 100px">Profession</th>
                  <th style="min-width: 100px">Desired Salary</th>
                  <th style="min-width: 101px">LinkedIn</th>
                  <th style="min-width: 202px">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, i) in data">
                  <tr v-bind:key="i" class="border__ch">
                    <td class="pl-0 pt-8">
                      <div class="d-flex align-items-center">
                        <v-checkbox
                          style="margin-top: -2%"
                          color="secondary"
                          hide-details
                          @change="bulkAction($event, item.job_seeker_id)"
                        ></v-checkbox>
                        <div class="symbol symbol-50 symbol-light mr-4">
                          <span class="symbol-label ch_img_mangeer">
                            <img
                              :src="mediaUrl + item.profile.profile_pic"
                              class=""
                              alt=""
                            />
                          </span>
                        </div>
                        <div>
                          <!-- <span
                            class="
                              text-dark-75
                              font-weight-bolder
                              d-block
                              font-size-lg
                            "
                            >{{ item.user.name }}</span
                          > -->
                          <a
                            class="m-0 Main_Blue"
                            style="font-size: 14px; font-weight: 600"
                            >{{ item.user.name }}</a
                          >
                          <!-- <span
                            v-html="item.profile.details"
                            class="m-0 Main_Blue"
                            style="font-size: 14px"
                          ></span> -->
                        </div>
                      </div>
                    </td>
                    <td>
                      <span class="m-0 Main_Blue" style="font-size: 14px">{{
                        item.profile.profession
                      }}</span>
                      <span
                        v-if="!item.profile.profession"
                        style="font-size: 14px; color: rgb(119, 119, 119)"
                        >Empaty</span
                      >
                    </td>
                    <td>
                      <span class="m-0 Main_Blue" style="font-size: 14px"
                        >{{ item.profile.desired_salary }}
                      </span>
                    </td>
                    <td>
                      <span
                        class="m-0 Main_Blue"
                        style="font-size: 14px"
                        v-if="item.profile.linkedin"
                        >{{ item.profile.linkedin }}</span
                      >
                      <span
                        class="m-0 Main_Blue"
                        style="font-size: 14px"
                        v-if="!item.profile.linkedin"
                      >
                        No add linkedin URL
                      </span>
                    </td>

                    <td class="pr-0 text-left">
                      <v-btn
                        class="
                          btn btn-sm
                          custom-delete-new-record-button
                          btn_row_ch
                          py-2
                          px-3 px-xxl-5
                          my-1
                        "
                        fab
                        small
                        @click="singleMsg(item)"
                      >
                        <v-icon dark>mdi-send</v-icon>
                      </v-btn>
                      <v-btn
                        class="
                          btn btn-sm
                          font-weight-bold
                          custom-delete-new-record-button
                          btn_row_ch
                          my-1
                          ,l-3
                        "
                        fab
                        small
                        @click="removeShortList(item.id)"
                      >
                        Del
                        <!-- <v-icon dark>mdi-minus</v-icon> -->
                      </v-btn>
                    </td>
                  </tr>

                  <tr class="border__ch" v-bind:key="i">
                    <td colspan="6" style="padding: 0">
                      <v-expansion-panels>
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            style="min-height: fit-content"
                          >
                            Profile
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div>
                              <div class="row d-none">
                                <div
                                  class="col col-md-12"
                                  style="display: flex"
                                >
                                  <div class="col col-md-8">
                                    <div class="col col-md-6">
                                      <b>Name:</b>&nbsp;&nbsp;
                                      {{ item.user.name }}
                                    </div>
                                    <div class="col col-md-6">
                                      <b>Email:</b>&nbsp;&nbsp;
                                      {{ item.user.email }}
                                    </div>
                                    <div class="col col-md-6">
                                      <b>Profession:</b>&nbsp;&nbsp;
                                      {{ item.profile.profession }}
                                    </div>
                                    <div class="col col-md-6">
                                      <b>Desired Salary:</b>&nbsp;&nbsp;
                                      {{ item.profile.desired_salary }}
                                    </div>
                                    <div class="col col-md-6">
                                      <b>LinkedIn:</b>&nbsp;&nbsp;
                                      {{ item.profile.linkedin }}
                                    </div>
                                    <div class="col col-md-6">
                                      <b>Website:</b>&nbsp;&nbsp;
                                      {{ item.profile.website }}
                                    </div>
                                    <div class="col col-md-6">
                                      <b>Details:</b>&nbsp;&nbsp;
                                      <span
                                        v-html="item.profile.details"
                                      ></span>
                                    </div>
                                  </div>
                                  <div class="col col-md-4">
                                    <div class="col col-md-6"></div>
                                    <div class="col col-md-6">
                                      <img
                                        :src="
                                          mediaUrl + item.profile.profile_pic
                                        "
                                        class="h-200 align-self-end"
                                        width="100"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- ============================= -->
                              <div class="row shorlistedCandidates_details">
                                <div class="col col-md-12 row m-0">
                                  <div class="col col-md-4 row m-0">
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Name:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                      >
                                        <!-- {{ item }} -->
                                        {{ item.user.name }}
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Email:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                      >
                                        {{ item.user.email }}
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Profession:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        v-if="item.profile.profession"
                                        style="font-size: 12px"
                                      >
                                        {{ item.profile.profession }}
                                      </div>
                                      <div
                                        class="col-7"
                                        v-if="!item.profile.profession"
                                        style="font-size: 12px"
                                      >
                                        Empaty
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Desired Salary:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.profile.desired_salary"
                                      >
                                        {{ item.profile.desired_salary }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.profile.desired_salary"
                                      >
                                        No add desired salary
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >LinkedIn:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.profile.linkedin"
                                      >
                                        {{ item.profile.linkedin }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.profile.linkedin"
                                      >
                                        No add Linkedin URL
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-5 p-0">
                                        <b
                                          class="m-0 Main_Blue"
                                          style="
                                            font-size: 12px;
                                            font-weight: 600;
                                          "
                                          >Website:</b
                                        >
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="item.profile.website"
                                      >
                                        {{ item.profile.website }}
                                      </div>
                                      <div
                                        class="col-7"
                                        style="font-size: 12px"
                                        v-if="!item.profile.website"
                                      >
                                        No add website URL
                                      </div>
                                    </div>
                                    <div class="col col-md-12 row m-0">
                                      <div class="col-md-12 mt-4 p-0">
                                        <a
                                          :href="
                                            /job-seeker-employability-profile/ +
                                            item.profile.user_id
                                          "
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <button
                                            type="button"
                                            class="btn btn_lood_ch p-2"
                                          >
                                            View Full Profile
                                          </button>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-2 text-right pr-0">
                                    <img
                                      :src="mediaUrl + item.profile.profile_pic"
                                      class="cv_image_by_ch"
                                      width="100"
                                      height="100"
                                      v-if="item.profile.profile_pic"
                                    />
                                    <img
                                      src="/images/profile_ch.jpg"
                                      class="cv_image_by_ch"
                                      width="100"
                                      height="100"
                                      v-if="!item.profile.profile_pic"
                                    />
                                  </div>
                                  <div class="col col-md-6">
                                    <div class="col col-md-12 p-0 row m-0">
                                      <b
                                        class="m-0 Main_Blue"
                                        style="
                                          font-size: 14px;
                                          font-weight: 600;
                                        "
                                        >Professional summary:</b
                                      >
                                      <!-- {{ item }} -->
                                      <span
                                        style="font-size: 13px"
                                        v-html="item.profile.details"
                                      ></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- ============================= -->
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>

                  <tr
                    v-bind:key="i"
                    v-if="currentMsgUser && currentMsgUser.id == item.id"
                  >
                    <td colspan="6" style="padding: 0">
                      <v-expansion-panels :value="0">
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            Send Direct Message
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div>
                              <div class="row">
                                <div
                                  class="col col-md-12"
                                  style="display: flex"
                                >
                                  <v-card>
                                    <v-card-title>
                                      <span class="headline"
                                        >Send direct message</span
                                      >
                                    </v-card-title>
                                    <div
                                      class="row"
                                      style="
                                        padding-left: 6%;
                                        padding-right: 6%;
                                      "
                                    >
                                      <div class="col-md-12">
                                        <v-text-field
                                          v-model="message_title"
                                          label="Subject"
                                        ></v-text-field>

                                        <v-file-input
                                          style="font-size: 8px"
                                          label="file"
                                          @change="Preview_image"
                                          v-model="file"
                                        >
                                        </v-file-input>
                                      </div>

                                      <label>Message</label>
                                      <vue-editor
                                        style="width: 100%; height: 80%"
                                        v-model="message"
                                      ></vue-editor>
                                    </div>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>

                                      <v-btn
                                        class="custom-submit-button"
                                        text
                                        @click="currentMsgUser = null"
                                        >Cancel</v-btn
                                      >
                                      <v-btn
                                        class="custom-cancel-button"
                                        text
                                        @click="sendMsg"
                                        ><b-spinner
                                          small
                                          type="grow"
                                          v-if="deleting"
                                        ></b-spinner
                                        >Send</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </div>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                  </tr>

                  <tr
                    v-if="data.length > 1"
                    v-bind:key="i"
                    style="background: #eeeeee"
                  >
                    <td colspan="6"><hr /></td>
                  </tr>
                </template>
                <tr v-if="data.length == 0">
                  <td colspan="6" style="text-align: center">No data found</td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-card>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      loading: true,
      token: `Bearer ${localStorage.getItem("token")}`,

      headers: [
        { text: "Job Title", value: "job_title" },
        { text: "City", value: "location_city" },
        { text: "Salary Min.", value: "salary_min" },
        { text: "Salary Max.", value: "salary_max" },
        { text: "Job Type", value: "job_type" },
        { text: "Job Function", value: "job_function" },
        { text: "Experience", value: "experience" },
        { text: "Education", value: "education" },
        { text: "Gender", value: "gender" },
        { text: "No. of Vacancies", value: "no_of_vacancies" },
        { text: "Expire at", value: "apply_by" },
        { text: "Status", value: "status" },
        { text: "Block", value: "block" },
        { text: "Short List", value: "action" },
      ],

      data: [],
      originalData: [],
      bulkActionArr: [],
      job_id: null,
      jobs: [],
      currentMsgUser: null,

      // message
      message_title: "",
      message: "",
      file: null,
      file_url: null,
      jobId: null,
      showBulkMsgForm: false,
    };
  },
  mounted() {
    this.fetchShortList(false);
    this.fetchCurrentJobs();
  },
  components: {
    VueEditor,
  },
  methods: {
    fetchShortList(v) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios.get(`${API_URL}/fetch-short-list`).then((res) => {
        if (v == true) {
          this.data = res.data;
        }
        this.data = res.data;
        this.originalData = res.data;
      });
    },

    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant: variant,
        solid: true,
      });
    },

    removeShortList(id) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios.delete(`${API_URL}/short-list/${id}`).then((res) => {
        // console.log(res.data);
        this.$bvToast.toast(res.data.msg, {
          title: `Message`,
          variant: `${res.data.type}`,
          solid: true,
        });
        this.fetchShortList(true);
      });
    },

    fetchCurrentJobs() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/jobs`)
        .then((res) => {
          res.data.forEach((element) => {
            this.jobs.push({
              value: element.id,
              text: element.job_title,
            });
          });
          this.jobs.unshift(...[{ value: null, text: "select job" }]);

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    myFunc(id) {
      // console.log(id);
      var temp = [];
      this.data = this.originalData;
      this.data.forEach((element) => {
        if (element.job_id == id) {
          temp.push(element);
        }
      });
      this.job_id = id;
      this.data = temp;
    },
    singleMsg(item) {
      this.currentMsgUser = item;
    },
    Preview_image() {
      this.file_url = this.file;
    },
    resetSendMsg() {
      this.msgUser = null;
      this.message_title = "";
      this.message = "";
      this.file = null;
      this.file_url = null;
      this.sendMsgPopup = false;
      this.currentMsgUser = null;
    },
    sendMsg() {
      let formData = new FormData();
      formData.append("message_title", this.message_title);
      formData.append("job_id", this.currentMsgUser.job_id);
      formData.append("message", this.message);
      formData.append("receiver_id", this.currentMsgUser.job_seeker_id);
      formData.append("file", this.file_url);

      axios.defaults.headers.common["Authorization"] = this.token;
      axios.post(`${API_URL}/send-message`, formData).then((res) => {
        Swal.fire({
          title: "",
          text: res.data,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.resetSendMsg();
      });
    },
    bulkAction(event, id) {
      var newArr = this.bulkActionArr;
      if (event == true) {
        newArr.push(id);
        this.bulkActionArr = newArr;
      } else {
        newArr = this.bulkActionArr.filter((item) => {
          return item !== id;
        });
        this.bulkActionArr = newArr;
      }
      // console.log("here: ", this.bulkActionArr);
    },

    bulkActionHandler() {
      // console.log(this.message_title);
      // console.log(this.job_id);
      // console.log(this.message);
      // console.log(this.bulkActionArr);
      // console.log(this.file_url);

      // return;
      let formData = new FormData();
      formData.append("message_title", this.message_title);
      formData.append("job_id", this.job_id);
      formData.append("message", this.message);
      formData.append("receiver_id", JSON.stringify(this.bulkActionArr));
      formData.append("file", this.file_url);

      axios.defaults.headers.common["Authorization"] = this.token;
      axios.post(`${API_URL}/send-message-bulk`, formData).then((res) => {
        Swal.fire({
          title: "",
          text: res.data,
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        this.showBulkMsgForm = false;
        this.resetSendMsg();
      });
    },
  },
};
</script>
